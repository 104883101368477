<script>
import stepsMixin from '@/mixins/stepsMixin'
import { mapGetters, mapMutations } from 'vuex'
import SvgIcon from '../../components/SvgIcon.vue'
export default {
  components: { SvgIcon },
	mixins: [stepsMixin],
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters({
			showHelp: 'arceStatus/showHelp'
		})
	},
	methods: {
		checkHelpers() {
			let help = this.$route.params.help == 'true' ||  this.$route.params.help == 'help'
			if(help) {
				this._setHelp(help)
			}
		},
		...mapMutations({
			_setHelp: 'arceStatus/setHelp'
		})
	},
	mounted() {
		// this._setHelp(true)
		this.checkHelpers()
		this.hiddenSteps()
		// this.showSteps('myTour')
	}
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1">
		<div class="col-12 col-lg-8 col-xl-9 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2 d-none">
					<div class="s-panel">
						<div class="s-panel__body">
							<a href="#">Biblioteca</a>
							<span class="px-1">/</span>
							<a href="#">Wiki Red BeyG</a>
							<span class="px-1">/</span>
							<a href="#">Tecnosoluciones móvil</a>
							<span class="px-1">/</span>
							<a href="#">RAR</a>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel s-panel--gray">
						<div class="s-panel__header d-flex align-items-center">
							<span class="s-icon-bg s-icon-bg--yellow">
								<SvgIcon name="opportunity" />
							</span>
							<div class="ml-2">
								<p class="m-0 small text-muted">Opportunities</p>
								<h3 class="title m-0">Live Opportunities</h3>
							</div>
						</div>
					</div>
					<div class="d-flex">
						<button class="s-tab">
							Opportunity
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Opportunity
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Client
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Products
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Status
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Expected
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Countries
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Closing Date
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
					</div>
					<div class="s-panel">
						<div class="py-2">
							<p class="text-center m-0 text-primary small">View all</p>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel s-panel--gray">
						<div class="s-panel__header d-flex align-items-center">
							<span class="s-icon-bg s-icon-bg--menta">
								<SvgIcon name="service_report" />
							</span>
							<div class="ml-2">
								<h3 class="title m-0">My last 12 months and future visite (5)</h3>
							</div>
							<div class="ml-auto">
								<button class="s-btn s-btn--outline text-primary">Open</button>
								<button class="s-btn s-btn--outline text-primary ml-1">Refresh</button>
							</div>
						</div>
					</div>
					<div class="d-flex">
						<button class="s-tab">
							Visit: Visit Name
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Client
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Start Date
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Type
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
						<button class="s-tab">
							Purpose Type
							<svg-icon  class="ml-auto" :name="'chevrondown'"></svg-icon>
						</button>
					
					</div>
					<div class="s-panel">
						<div class="py-2">
							<p class="text-center m-0 text-primary small">View all</p>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header d-flex align-items-center">
							
							<div class="ml-2">
								<h3 class="title m-0">News</h3>
							</div>
							
						</div>
						<div class="s-panel__body d-flex flex-wrap">
							<div class="col-4 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">Hang up on robocalls promising to lower rates or fix credit</h3>
									<p class="text-muted m-0">General Business News</p>
									<p class="text-muted m-0">Msn . 1h</p>
									<p class="m-0">Consumers who are facing a cash crunch are once again warned to avoid paying upfront fees and falling for surefire guarantees when it comes to fixing their credit problems.</p>
								</div>
							</div>
							<div class="col-4 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">Hang up on robocalls promising to lower rates or fix credit</h3>
									<p class="text-muted m-0">General Business News</p>
									<p class="text-muted m-0">Msn . 1h</p>
									<p class="m-0">Consumers who are facing a cash crunch are once again warned to avoid paying upfront fees and falling for surefire guarantees when it comes to fixing their credit problems.</p>
								</div>
							</div>
							<div class="col-4 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">Hang up on robocalls promising to lower rates or fix credit</h3>
									<p class="text-muted m-0">General Business News</p>
									<p class="text-muted m-0">Msn . 1h</p>
									<p class="m-0">Consumers who are facing a cash crunch are once again warned to avoid paying upfront fees and falling for surefire guarantees when it comes to fixing their credit problems.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<div class="col-12 col-lg-4 col-xl-3 p-0">
			<div class="d-flex flex-wrap">
				<div class="col-12 px-2 py-2">
					<div class="s-panel s-panel--gray">
						<div class="s-panel__header">
							<h3 class="title">Pricing Calculator</h3>
						</div>
						<div class="s-panel__body">
							<div class="text-center py-3">
								<button class="btn btn-blue rounded">CLAN Pricing</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header">
							<h3 class="title">Today's Tasks</h3>
						</div>
						<div class="s-panel__body">
							<div class="text-center">
								<img class="s-panel__svg" src="@/assets/simulator/noTasks.svg" alt="">
								<p class="m-0 px-5">Nothing due today. Be a go-getter, and check back soon.</p>
							</div>
						</div>
						<div class="s-panel__footer">
							<div class="text-center">
								<a href="#">View all</a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header">
							<h3 class="title">Today's Events</h3>
						</div>
						<div class="s-panel__body">
							<div class="text-center">
								<img class="s-panel__svg" src="@/assets/simulator/noTasks.svg" alt="">
								<p class="m-0 px-5">Nothing due today. Be a go-getter, and check back soon.</p>
							</div>
						</div>
						<div class="s-panel__footer">
							<div class="text-center">
								<a href="#">View all</a>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>

	</div>
</template>